import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class NoContent extends Vue {
  @Prop({ required: true })
  public readonly title: string

  @Prop({ default: '' })
  public readonly btnTitle: string

  public onActionBtnClick(): void {
    this.$emit('onActionBtnClick')
  }
}
