import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { Validator } from '@/App/Mixins'
import DeviceSelect from '@/App/Components/DeviceSelect/Device-Select.vue'
import { DeviceSettings as IDeviceSettings, Sensor } from '@/App/Services/interfaces/dashboard.interface'
import { ValidationResult } from '@/types'

@Component({
  components: { DeviceSelect }
})
export default class DeviceSettings extends Mixins(Validator) {
  @Prop({ default: '' })
  private readonly value: IDeviceSettings

  public deviceId: number | null = null
  public sensors: Sensor[] = []

  private mounted(): void {
    this.valueChanged(this.value)
  }

  @Watch('value')
  private valueChanged(val: IDeviceSettings): void {
    this.deviceId = val.deviceId
    this.sensors = val.sensors
  }

  @Watch('deviceId')
  private deviceIdChanged(): void {
    this.onSettingsChanged()
  }

  public removeDevice(): void {
    this.$emit('onDeviceRemove')
  }

  public addSensor(): void {
    this.sensors.push({
      fieldName: '',
      simpleName: ''
    })
  }

  public sensorNameChanged(): void {
    this.onSettingsChanged()
  }

  public removeSensor(idx: number): void {
    this.sensors.splice(idx, 1)

    this.onSettingsChanged()
  }

  private onSettingsChanged(): void {
    if (this.deviceId) {
      const data: IDeviceSettings = {
        _id: this.value._id,
        deviceId: this.deviceId,
        sensors: this.sensors
      }

      this.$emit('input', data)
    }
  }

  public simpleNameValidator(idx: number): ValidationResult {
    return this.validator.isEmpty(this.sensors[idx].simpleName)
  }
}
