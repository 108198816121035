
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { ChartData, ChartOptions } from 'chart.js'
import { Bar, mixins } from 'vue-chartjs'

@Component
export default class DonutChart extends Mixins(Bar, mixins.reactiveProp) {
	@Prop({ required: true })
	public readonly chartData: ChartData

  public options: ChartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		legend: {
			display: false
		},
		scales: {
			xAxes: [{
				ticks: {
					display: false
				}
			}]
		}
	}

  async mounted() {
		this.renderChart(this.chartData, this.options)
  }
}
