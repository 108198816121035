import { render, staticRenderFns } from "./DashboardCard.vue?vue&type=template&id=0f2b1800&scoped=true&"
import script from "./DashboardsCard.ts?vue&type=script&lang=ts&"
export * from "./DashboardsCard.ts?vue&type=script&lang=ts&"
import style0 from "./DashboardsCard.scss?vue&type=style&index=0&id=0f2b1800&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f2b1800",
  null
  
)

export default component.exports