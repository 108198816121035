import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { DashboardService } from '@/App/Services/Dashboard.service'
import SelectDashboard from '../../../Components/DashboardSelect/Dashboard-Select.vue'
import UserSelect from '../../../Components/UserSelect/User-Select.vue'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { UserInfo } from '@/App/Services/interfaces/user.interface'

const sharedStore = namespace('Shared')

@Component({
  components: { SelectDashboard, UserSelect }
})
export default class ShareDashboardDialog extends Vue {
  $refs: {
    form: HTMLFormElement
  }

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  @sharedStore.State
  private readonly userInfo: UserInfo

  public isOpen = false
  public isLoading = false
  public form = false
  public dashboardsIds: string[] = []
  public usersIds: string[] = []

  public open(dashboardsIds: string[] = []): void {
    this.isOpen = true
    this.dashboardsIds = dashboardsIds
  }

  public close(): void {
    this.isOpen = false

    this.dashboardsIds = []
    this.usersIds = []

    this.$refs.form.resetValidation()
  }

  public async share(): Promise<void> {
    try {
      this.isLoading = true

      for (const userId of this.usersIds) {
        for (const dashboardId of this.dashboardsIds) {
          await DashboardService.share(dashboardId, userId)
        }
      }

      this.setNotification({ text: this.$t('dashboardsPage.shareDashboardDialog.dashboardSharedNotificationText').toString() })

      this.close()
    } catch {} finally {
      this.isLoading = false
    }
  }
}
