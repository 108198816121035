import { Component, Vue, Prop } from 'vue-property-decorator'
import { ChartData, ChartDataSets } from 'chart.js'
import { default as dayjs } from 'dayjs'
import { FlespiService } from '@/App/Services/Flespi.service'
import ChartDonut from '@/App/Components/Charts/Donut-Chart.vue'
import ChartBar from '@/App/Components/Charts/Bar-Chart.vue'
import { ChartType, IDeviceSettings, DefaultDateRange, IntervalType } from '@/App/Services/interfaces/dashboard.interface'
import { DateTimeInterval } from '@/App/Components/Pickers'

@Component({
  components: { ChartDonut, ChartBar }
})
export default class Detailed extends Vue {
  @Prop({ required: true })
  public readonly deviceSettings: IDeviceSettings[]

  @Prop({ required: true })
  public readonly calculatorId: number

  @Prop({ required: true })
  public readonly chartType: ChartType

  @Prop()
  private readonly dateTimeInterval: DateTimeInterval

  @Prop({ default: 'MM.DD.YYYY' })
  private readonly timeFormat: string

  @Prop({ default: 'begin' })
  private readonly intervalType: IntervalType

  @Prop({ default: false })
  private readonly isTotalRowShow: boolean

  @Prop()
  private readonly defaultDateRange: DefaultDateRange

  public tableData: TableData = {
    headers: ['Date'],
    data: []
  }
  public chartData: ChartData | null = null
  public total: number[] = []

  private mounted(): void {
    this.update()
  }

  private async setData(): Promise<void> {
    try {
      this.$emit('toggleLoading')

      const labels: string[] = []

      const data = await FlespiService.getDeviceCalculations(
        this.deviceSettings[0].deviceId,
        this.calculatorId,
        await this.getDataRange()
      )

      if (!data.length) {
        this.$emit('onNoDataAvailable')
        return
      }

      let label = ''
      this.deviceSettings[0].sensors.forEach(s => {
        this.tableData.headers.push(s.simpleName)
      })

      data.forEach((d, idx) => {
        switch (this.intervalType) {
          case 'begin':
            label = dayjs(d.begin * 1000).format(this.timeFormat)
            break
          case 'end':
            label = dayjs(d.end * 1000).format(this.timeFormat)
            break
          case 'begin-end':
            label = dayjs(d.begin * 1000).format(this.timeFormat) + ' - ' + dayjs(d.end * 1000).format(this.timeFormat)
            break
        }

        labels.push(label)
        this.tableData.data[idx] = [label]
        this.deviceSettings[0].sensors.forEach((s, index) => {
          if (d[s.fieldName] !== undefined) {
            const value = d[s.fieldName].toFixed(2)
            this.tableData.data[idx].push(value)

            this.total[index] = this.total[index] ? this.total[index] : 0
            this.total[index] = +(this.total[index] + +value).toFixed(2)
          } else {
            this.tableData.data[idx].push(this.$t('dashboardsPage.dashboardCard.insightDashboard.detailed.noDataText').toString())
          }

        })
      })

      const datasets: ChartDataSets[] = []
      this.deviceSettings[0].sensors.forEach(() => {
        datasets.push({
          backgroundColor: '#' + Math.floor(Math.random()*16777215).toString(16),
          data: []
        })
      })

      this.tableData.data.forEach(d => {
        d.forEach((value, index) => {
          if (index !== 0) {
            datasets[index - 1].data!.push(+value)
          }
        })
      })

      this.chartData = {
        labels,
        datasets
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.$emit('toggleLoading')
    }
  }

  private async getDataRange(): Promise<DateTimeInterval> {
    // For avoid trouble when dateTimeInterval can be empty
    await new Promise(r => setTimeout(r, 50))
    if (this.dateTimeInterval.from && this.dateTimeInterval.to) {
      return this.dateTimeInterval
    } else {
      return {
        from: dayjs().subtract(this.defaultDateRange.last, this.defaultDateRange.unit).unix(),
        to: +(Date.now() / 1000).toFixed(0)
      }
    }
  }

  public update(): void {
    this.tableData = {
      headers: ['Interval'],
      data: []
    }
    this.total = []

    this.setData()
  }

  get isDonutChartShow(): boolean {
    return this.chartType === 'donut'
  }

  get isBarChartShow(): boolean {
    return this.chartType === 'bar'
  }
}

interface TableData {
  headers: string[]
  data: string[][]
}
