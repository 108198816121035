import { ChartType, DashboardType, DataType, StoreDashboard } from '@/App/Services/interfaces/dashboard.interface'
import { VuetifySelect } from '@/interfaces/vuetify'
import i18n from '@/plugins/i18n'

export const TYPES_ITEMS: DashboardType[] = [ 'monitoring', 'insights' ]
export const CHART_TYPES_ITEMS: ChartType[] = [ 'donut', 'bar' ]
export const DASHBOARD_DATA_TYPE: DataType[] = [ 'summary', 'detailed' ]
export const DASHBOARD_DEFAULT_DATA_RANGE_UNITS: VuetifySelect[] = [
  { value: 'hours', text: i18n.tc('dashboardsPage.dashboardDialog.constants.hoursText') },
  { value: 'days', text: i18n.tc('dashboardsPage.dashboardDialog.constants.daysText') },
  { value: 'months', text: i18n.tc('dashboardsPage.dashboardDialog.constants.monthsText') },
  { value: 'years', text: i18n.tc('dashboardsPage.dashboardDialog.constants.yearsText') }
]
export const DASHBOARD_FORM: StoreDashboard = {
  name: '',
  type: 'monitoring',
  deviceSettings: [],
  calculatorId: 0,
  chartType: 'donut',
  dataType: 'summary',
  timeFormat: 'MM.DD.YYYY',
  intervalType: 'begin',
  isTotalRowShow: false,
  defaultDateRange: {
    last: 1,
    unit: 'days'
  }
}
