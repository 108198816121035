import { Widget } from '@/store/interfaces/widget.interface'
import { ToggleFormData } from '@/App/Pages/Widgets/Forms/Toggle/ToggleForm'

export { VisualizationCore, GaugeOptions } from './VisualizationCore'

export function getWidgetColor(widget: Widget): string {
  const { lastValue, sensorType, isMonitoring, monitoringDuration, lastUpdateTime } = widget

  if (isMonitoring && monitoringDuration && lastUpdateTime) {
    if ((lastUpdateTime * 1000) + monitoringDuration * 1000 < Date.now()) {
      return '#f44336'
    }
  }

  if (['Custom', 'Temperature', 'Voltage', 'Humidity', 'Light (lux)'].includes(sensorType)) {
    const { lowLevelValue, midLevelValue, highLevelValue, lowLevelColor, midLevelColor, highLevelColor } = widget

    if (lowLevelValue === 0 && midLevelValue === 0 && highLevelValue === 0) {
      return widget.color
    }

    if (
      lastValue !== undefined &&
      lowLevelValue !== undefined &&
      midLevelValue !== undefined &&
      highLevelValue !== undefined
    ) {
      if (lowLevelValue && +lastValue <= lowLevelValue) {
        return lowLevelColor!
      } else if (+lastValue >= lowLevelValue && +lastValue <= highLevelValue) {
        return midLevelColor!
      } else {
        return highLevelColor!
      }
    }
  }

  if (sensorType === 'Custom digital' || sensorType === 'Toggle') {
    const { trueValue, trueColor, falseColor } = widget

    return lastValue === trueValue ? trueColor! : falseColor!
  }

  return widget.color
}

export function getToggleFormData(widget: Widget): ToggleFormData {
  return {
    deviceId: widget.deviceId!,
    falseActionPayload: widget.falseActionPayload!,
    falseValue: widget.falseValue!,
    param: widget.param!,
    settingsName: widget.settingsName!,
    text: widget.lastValue === widget.trueValue ? widget.trueText! : widget.falseText!,
    trueActionPayload: widget.trueActionPayload!,
    trueValue: widget.trueValue!,
    widgetId: widget._id
  }
}
