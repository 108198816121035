import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import DeviceSelect from '@/App/Components/DeviceSelect/Device-Select.vue'
import CalculatorSelect from '@/App/Components/CalculatorSelect/Calculator-Select.vue'
import DateFormatSelect from './DateFormatSelect/Date-Format-Select.vue'
import IntervalTypeSelect from './IntervalTypeSelect/Interval-Type-Select.vue'
import DeviceSettings from './DeviceSettings/Device-Settings.vue'
import { DashboardService } from '@/App/Services/Dashboard.service'
import * as constants from './constants'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { Dashboard, IDashboard } from '@/App/Services/interfaces/dashboard.interface'
import { UserInfo } from '@/App/Services/interfaces/user.interface'

const sharedStore = namespace('Shared')
const dashboardStore = namespace('Dashboard')

@Component({
  components: { DeviceSelect, CalculatorSelect, DateFormatSelect, IntervalTypeSelect, DeviceSettings }
})
export default class DashboardDialog extends Mixins(Validator) {
  $refs: {
    form: HTMLFormElement
  }

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  @sharedStore.State
  private readonly userInfo: UserInfo

  @sharedStore.Mutation
  private readonly setUserInfo: (user: UserInfo) => void

  @dashboardStore.Mutation
  private readonly addDashboard: (dashboard: Dashboard) => void

  @dashboardStore.Mutation
  private readonly updateDashboard: (dashboard: Dashboard) => void

  public isOpen = false
  public isLoading = false
  public form = false
  public typesItems = constants.TYPES_ITEMS
  public chartTypeItems = constants.CHART_TYPES_ITEMS
  public dataTypeItems = constants.DASHBOARD_DATA_TYPE
  public defaultDataRangeItems = constants.DASHBOARD_DEFAULT_DATA_RANGE_UNITS
  public dashboard = { ...constants.DASHBOARD_FORM }
  public id: string | null = null

  public open(): void {
    this.isOpen = true
  }

  public close(): void {
    this.isOpen = false

    this.dashboard = { ...constants.DASHBOARD_FORM, deviceSettings: [] }
    this.id = null

    this.$refs.form.resetValidation()
  }

  public addDevice(): void {
    this.dashboard.deviceSettings.push({
      _id: Math.floor(Math.random()*16777215).toString(16),
      deviceId: 0,
      sensors: []
    })
  }

  public removeDevice(id: string): void {
    this.dashboard.deviceSettings = this.dashboard.deviceSettings.filter(d => d._id !== id)
  }

  public edit(dashboard: IDashboard): void {
    this.id = dashboard._id
    delete dashboard._id

    this.dashboard = dashboard

    this.open()
  }

  public async submit(): Promise<void> {
    if (this.id) {
      await this.update()
    } else {
      await this.store()
    }
  }

  public async store(): Promise<void> {
    try {
      this.isLoading = true
      const dashboard = await DashboardService.store(this.dashboard)

      this.addDashboard(dashboard)
      const dashboardsLayout = [
        ...this.userInfo.settings.dashboardsLayout,
        {
          w: 3,
          h: 3,
          x: 0,
          y: 0,
          i: dashboard._id
        }
      ]

      this.setUserInfo({ ...this.userInfo, settings: { ...this.userInfo.settings, dashboardsLayout } })

      this.setNotification({ text: this.$t('dashboardsPage.dashboardDialog.dashboardSavedNotificationText').toString() })

      this.close()
    } catch {} finally {
      this.isLoading = false
    }
  }

  public async update(): Promise<void> {
    try {
      this.isLoading = true

      this.updateDashboard(await DashboardService.update(this.id!, this.dashboard))

      this.setNotification({ text: this.$t('dashboardsPage.dashboardDialog.dashboardUpdatedNotificationText').toString() })

      this.close()
    } catch {} finally {
      this.isLoading = false
    }
  }

  get title(): string {
    return this.id
      ? `${this.$t('dashboardsPage.dashboardDialog.dialogTitleEdit').toString()} ${this.dashboard.name}`
      : this.$t('dashboardsPage.dashboardDialog.dialogTitleCreate').toString()
  }

  get buttonText(): string {
    return this.id
      ? this.$t('dashboardsPage.dashboardDialog.updateBtnText').toString()
      : this.$t('dashboardsPage.dashboardDialog.saveBtnText').toString()
  }

  get isInsightsFormShow(): boolean {
    return this.dashboard.type === 'insights'
  }

  get isAddDeviceBtnShow(): boolean {
    return this.dashboard.dataType === 'summary' || (this.dashboard.dataType === 'detailed' && this.dashboard.deviceSettings.length < 1)
  }
}
