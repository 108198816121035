import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { UserService } from '@/App/Services/User.service'
import { VuetifySelect } from '@/interfaces/vuetify'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { ValidationResult } from '@/types'

const sharedStore = namespace('Shared')

@Component
export default class UserSelect extends Mixins(Validator) {
  @Prop({ default: '' })
  private readonly value: string

  @Prop()
  private readonly excludeUser: string

  @Prop({ default: false })
  public readonly multiple: boolean

  @Prop({ default: '' })
  public readonly hint: string

  @Prop({ default: false })
  public readonly hintAlwaysShow: boolean

  @Prop({ default: false })
  public readonly required!: boolean

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public userId: string | string[] = ''
  public usersItems: VuetifySelect[] = []
  public isLoading = false

  private async mounted(): Promise<void> {
    try {
      this.isLoading = true

      if (this.multiple) {
        this.userId = []
      }

      const users = await UserService.fetch()

      this.usersItems = users.map(u => {
        return { value: u._id, text: u.login }
      })

      if (this.excludeUser) {
        this.usersItems = this.usersItems.filter(u => u.value !== this.excludeUser)
      }

      this.userId = this.value
    } catch {} finally {
      this.isLoading = false
    }
  }
  public validation(): ValidationResult {
    if (!this.required) {
      return true
    }

    if (!this.multiple && !Array.isArray(this.userId)) {
      return this.validator.isEmpty(this.userId)
    } else {
      if (!this.userId.length) {
        return this.$t('userSelectComponent.validationError').toString()
      } else {
        return true
      }
    }
  }

  public onUserSelect(): void {
    this.$emit('input', this.userId)
  }

  @Watch('value')
  private valueChanged(val: string | string[]): void {
    this.userId = val
  }
}
