<template>
	<v-row class="card-body">
		<v-col
			class="pt-0 d-flex flex-column align-center justify-center"
			cols="12"
		>
			<h2>
				{{ $t('dashboardsPage.dashboardCard.noShortcuts.noShortcutsText') }}
			</h2>
			<div class="mt-3">
				{{ $t('dashboardsPage.dashboardCard.noShortcuts.addShortcutsText') }}
				<v-btn
					class="caption ml-2"
					color="grey darken-2"
					dark
					x-small
					:to="link"
				>
					<v-icon
						right
						dark
						class="pr-5"
						size="14"
					>
						fa fa-expand
					</v-icon>
					{{ $t('dashboardsPage.dashboardCard.noShortcuts.fullViewBtnText') }}
				</v-btn>
			</div>
		</v-col>
	</v-row>
</template>

<script>
export default {
  name: 'No-Shortcuts',
	props: {
		link: {
			require: true,
			type: String
		}
	}
}
</script>
<style scoped>
	.card-body {
		position: absolute;
		width: 99%;
		height: 89%;
	}
</style>
