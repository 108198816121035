import { Prop, Vue } from 'vue-property-decorator'
import { LinearGaugeOptions, RadialGaugeOptions } from 'canvas-gauges'
import { Units } from '@/types'

export class VisualizationCore extends Vue {
  @Prop({ required: true })
  protected readonly value : number

  @Prop({ required: true })
  protected readonly options : GaugeOptions

  protected commonOptions = {
    renderTo: '',
    minorTicks: 10,
    animationRule: 'linear',
    animationDuration: 1500,
    borderShadowWidth: 0,
    colorUnits: '#fff',
    colorTitle: '#fff',
    colorNumbers: '#fff',
    borders: false
  }

  protected fillOptions(gaugeOptions: LinearGaugeOptions | RadialGaugeOptions, widgetOptions: GaugeOptions) {
    gaugeOptions = {
      ...gaugeOptions,
      ...widgetOptions,
      units: false,
      minValue: widgetOptions.visualTypeMinValue,
      maxValue: widgetOptions.visualTypeMaxValue,
      highlights: []
    }

    gaugeOptions.highlights?.push({
      from: widgetOptions.visualTypeMinValue,
      to: widgetOptions.midLevelValue || 0,
      color: widgetOptions.lowLevelColor || '#fff'
    })

    if (widgetOptions.midLevelValue || widgetOptions.midLevelValue === 0) {
      gaugeOptions.highlights?.push({
        from: widgetOptions.midLevelValue,
        to: widgetOptions.highLevelValue || widgetOptions.visualTypeMaxValue,
        color: widgetOptions.midLevelColor || '#fff'
      })
    }

    if (widgetOptions.highLevelValue || widgetOptions.highLevelValue === 0) {
      gaugeOptions.highlights?.push({
        from: widgetOptions.highLevelValue,
        to: widgetOptions.visualTypeMaxValue,
        color: widgetOptions.highLevelColor || '#fff'
      })
    }

    gaugeOptions.majorTicks = this.calculateMajorTicks(
      widgetOptions.visualTypeMinValue,
      widgetOptions.visualTypeMaxValue,
      widgetOptions.visualTypeTickInterval
    )

    return gaugeOptions
  }

  protected calculateMajorTicks(minVal: number, maxVal: number, tickInterval: number): number[] {
    const majorTicks: number[] = [minVal]
    const step = (maxVal - minVal) / tickInterval
    let iteration = minVal
    for (let i = 0; i < step; i++) {
      iteration += +tickInterval
      majorTicks.push(iteration)
    }

    return majorTicks
  }
}

export interface GaugeOptions {
  prefix: string
  unit: Units,
  postfix: string
  colorPlate: string,
  lowLevelValue?: number
  lowLevelColor?: string
  midLevelValue?: number
  midLevelColor?: string
  highLevelValue?: number
  highLevelColor?: string
  visualTypeMinValue: number
  visualTypeMaxValue: number
  visualTypeTickInterval: number
}
