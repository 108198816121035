import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { FlespiService } from '@/App/Services/Flespi.service'
import { UpdateWidget } from '@/store/interfaces/widget.interface'

const sharedStore = namespace('Shared')
const widgetStore = namespace('Widget')

@Component
export default class ToggleForm extends Vue {
  @Prop({ required: true, default: '' })
  public readonly value: string

  @Prop({ required: true })
  public readonly options: ToggleFormData

  @Prop({ default: false })
  public readonly isShortcutForm: boolean

  @Prop({ default: false })
  public readonly isPreviewMode: boolean

  @sharedStore.Mutation
  private setNotification: (notification: Notification) => void

  @widgetStore.Mutation
  private updateWidget: (widget: UpdateWidget) => void

  public toggleState = false

  public async changeSettings(): Promise<void> {
    let payloadValue: string | number | boolean = this.toggleState ?
      this.options.falseActionPayload :
      this.options.trueActionPayload

    if (this.options.trueActionPayload === 'true' || this.options.trueActionPayload === 'false') {
      payloadValue = this.value !== 'true'
    }

    await FlespiService.updateDeviceSetting(
      this.options.deviceId,
      {
        settingName: this.options.settingsName,
        payload: {
          [this.options.param]: payloadValue
        }
      })

    this.updateWidget({
      _id: this.options.widgetId,
      lastValue: this.toggleState ? this.options.trueValue : this.options.falseValue
    })

    this.setNotification({ text: this.$t('widgetsPage.forms.toggle.commandSentOnDeviceNotificationText').toString() })
  }

  private mounted(): void {
    this.valueChanged()
  }

  @Watch('value')
  private valueChanged(): void {
    this.toggleState = this.options.trueValue === this.value
  }
}

export interface ToggleFormData {
  deviceId: number
  settingsName: string
  text: string
  trueValue: string
  falseValue: string
  trueActionPayload: string
  falseActionPayload: string
  widgetId: string
  param: string
}
