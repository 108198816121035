<template>
	<div
		style="height: 100%"
		class="d-flex justify-center align-center w100"
	>
		<v-progress-circular
			:width="width"
			:color="color"
			:size="size"
			indeterminate
		/>
	</div>
</template>

<script>
export default {
name: 'Circular-Loader',
	props: {
		size: {
			Type: Number,
			default: 200
		},
		width: {
			Type: Number,
			default: 12
		},
		color: {
			type: String,
			default: 'primary'
		}
	}
}
</script>
