import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import Detailed from './Detailed/Detailed.vue'
import Summary from './Summary/Summary.vue'
import CircularLoader from '@/App/Components/CircularLoader/Circular-Loader.vue'
import {
  ChartType,
  IDeviceSettings,
  DataType,
  DefaultDateRange,
  IntervalType
} from '@/App/Services/interfaces/dashboard.interface'
import { DateTimeInterval } from '@/App/Components/Pickers'

@Component({
  components: { CircularLoader, Detailed, Summary }
})
export default class InsightDashboard extends Vue {
  $refs: {
    form: HTMLFormElement
  }

  @Prop({ required: true })
  public readonly deviceSettings: IDeviceSettings[]

  @Prop({ required: true })
  public readonly calculatorId: number

  @Prop({ required: true })
  public readonly dataType: DataType

  @Prop({ required: true })
  public readonly chartType: ChartType

  @Prop()
  private readonly dateTimeInterval: DateTimeInterval

  @Prop({ default: 'MM.DD.YYYY' })
  private readonly timeFormat: string

  @Prop({ default: 'begin' })
  private readonly intervalType: IntervalType

  @Prop({ default: false })
  private readonly isTotalRowShow: boolean

  @Prop()
  private readonly defaultDateRange: DefaultDateRange

  public isLoading = false
  public isNoData = false
  public inUpdateState = false
  private timout: number

  private async mounted(): Promise<void> {
    try {
      this.timout = setInterval(() => this.updateData(), 18e5)
    } catch {}
  }

  private destroyed(): void {
    clearInterval(this.timout)
  }

  public async updateData(): Promise<void> {
    // For avoid any updates when triggered any watchers
    if (!this.inUpdateState) {
      this.inUpdateState = true
      this.isNoData = false

      this.$refs.form.update()

      setTimeout(() => this.inUpdateState = false, 1000)
    }
  }

  public toggleLoading(): void {
    this.isLoading = !this.isLoading
  }

  public setNoData(): void {
    this.isNoData = true
  }

  @Watch('dateTimeInterval')
  private dateTimeIntervalChanged() {
    this.updateData()
  }

  @Watch('dataType')
  private dataTypeChanged(): void {
    this.updateData()
  }

  @Watch('calculatorId')
  private calculatorIdChanged(): void {
    this.updateData()
  }

  @Watch('deviceSettings')
  private devicesChanged(): void {
    this.updateData()
  }

  @Watch('defaultDateRange')
  DefaultDateRangeChanged(): void {
    this.updateData()
  }
}
