import { render, staticRenderFns } from "./Insight-Dashboard.vue?vue&type=template&id=71e09fbb&scoped=true&"
import script from "./InsightDashboard.ts?vue&type=script&lang=ts&"
export * from "./InsightDashboard.ts?vue&type=script&lang=ts&"
import style0 from "./InsightDashboard.scss?vue&type=style&index=0&id=71e09fbb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e09fbb",
  null
  
)

export default component.exports