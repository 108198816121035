import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Menu extends Vue {
  public menuItems: MenuItem[] = [
    {
      icon: 'fa fa-edit',
      title: this.$t('dashboardsPage.dashboardCard.header.menu.editText').toString(),
      action: () => this.$emit('onEdit')
    },
    {
      icon: 'fa fa-share',
      title: this.$t('dashboardsPage.dashboardCard.header.menu.shareText').toString(),
      action: () => this.$emit('onShare')
    },
    {
      icon: 'fa fa-trash',
      title: this.$t('dashboardsPage.dashboardCard.header.menu.trashText').toString(),
      color: 'red',
      action: () => this.$emit('onRemove')
    }
  ]
  public isSettingsIconHovered = false

  public isSettingsHover(status: boolean): void {
    this.isSettingsIconHovered = status
  }

  get icon(): string {
    return this.isSettingsIconHovered ? 'fa fa-cog fa fa-spin' : 'fa fa-cog'
  }
}

interface MenuItem {
  icon: string
  title: string
  color?: string
  action:() => void
}
