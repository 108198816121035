import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { VuetifySelect } from '@/interfaces/vuetify'
import { Validator } from '@/App/Mixins'

@Component
export default class DateFormatSelect extends Mixins(Validator) {
  @Prop({ default: 'MM.DD.YYYY' })
  private readonly value: string

  public format = 'Date'
  public formatItems: VuetifySelect[] = [
    { value: 'DD.MM.YYYY', text: this.$t('dashboardsPage.dashboardDialog.dateFormatSelect.dateText').toString() },
    { value: 'HH:mm:ss', text: this.$t('dashboardsPage.dashboardDialog.dateFormatSelect.timeText').toString() },
    { value: 'DD.MM.YYYY HH:mm:ss', text: this.$t('dashboardsPage.dashboardDialog.dateFormatSelect.dateTimeText').toString() }
  ]
  public isLoading = false

  @Watch('value')
  private valueChanged(val: string): void {
    this.format = val
  }

  private mounted(): void {
    this.format = this.value

    this.$emit('input', this.format)
  }

  public onSelect(): void {
    this.$emit('input', this.format)
  }
}
