import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { DashboardService } from '@/App/Services/Dashboard.service'
import { VuetifySelect } from '@/interfaces/vuetify'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { ValidationResult } from '@/types'

const sharedStore = namespace('Shared')

@Component
export default class DashboardSelect extends Mixins(Validator) {
  @Prop({ default: '' })
  public readonly value: string | string[]

  @Prop()
  public readonly required!: boolean

  @Prop({ default: false })
  public readonly multiple: boolean

  @Prop({ default: false })
  public readonly excludeInsight: boolean

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public isLoading = false
  public dashboardId: string | string[] = ''
  public dashboardsItems: VuetifySelect[] = []

  private async fillDashboardsItems(): Promise<void> {
    try {
      this.isLoading = true

      this.dashboardsItems = []

      const dashboards = await DashboardService.fetch()

      dashboards.forEach(d => {
        if (this.excludeInsight) {
          if (d.type !== 'insights') {
            this.dashboardsItems.push({ value: d._id, text: d.name })
          }
        } else {
          this.dashboardsItems.push({ value: d._id, text: d.name })
        }
      })
    } catch {} finally {
      this.isLoading = false
    }
  }

  public validation(): ValidationResult {
    if (!this.required) {
      return true
    }

    if (!this.multiple && !Array.isArray(this.dashboardId)) {
      return this.validator.isEmpty(this.dashboardId)
    } else {
      if (!this.dashboardId.length) {
        return this.$t('dashboardSelectComponent.validationError').toString()
      } else {
        return true
      }
    }
  }

  public onDashboardSelect(): void {
    this.$emit('input', this.dashboardId)
  }

  @Watch('value')
  private valueChanged(val: string | string[]): void {
    this.dashboardId = val
  }

  private mounted(): void {
    this.fillDashboardsItems()

    this.dashboardId = this.value
  }
}
