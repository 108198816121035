import { render, staticRenderFns } from "./Collapsed-Dashboards-Dialog.vue?vue&type=template&id=1a9a68e0&"
import script from "./CollapsedDashboardsDialog.ts?vue&type=script&lang=ts&"
export * from "./CollapsedDashboardsDialog.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports