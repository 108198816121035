import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { VuetifySelect } from '@/interfaces/vuetify'
import { Validator } from '@/App/Mixins'

@Component
export default class IntervalTypeSelect extends Mixins(Validator) {
  @Prop({ default: 'begin' })
  private readonly value: string

  public interval = 'begin'
  public formatItems: VuetifySelect[] = [
    { value: 'begin', text: this.$t('dashboardsPage.dashboardDialog.intervalTypeSelect.fromText').toString() },
    { value: 'end', text: this.$t('dashboardsPage.dashboardDialog.intervalTypeSelect.toText').toString() },
    { value: 'begin-end', text: this.$t('dashboardsPage.dashboardDialog.intervalTypeSelect.fromToText').toString() }
  ]
  public isLoading = false

  @Watch('value')
  private valueChanged(val: string): void {
    this.interval = val
  }

  private mounted(): void {
    this.interval = this.value

    this.$emit('input', this.interval)
  }

  public onSelect(): void {
    this.$emit('input', this.interval)
  }
}
