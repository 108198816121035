import { Component, Vue, Prop } from 'vue-property-decorator'
import ToggleForm from '../../../Widgets/Forms/Toggle/Toggle-Form.vue'
import { getToggleFormData, getWidgetColor } from '@/App/Pages/Widgets/Utils'
import { Widget } from '@/store/interfaces/widget.interface'
import { ToggleFormData } from '@/App/Pages/Widgets/Forms/Toggle/ToggleForm'

@Component({
  components: { ToggleForm }
})
export default class Menu extends Vue {
  @Prop()
  public readonly widget: Widget

  get backgroundColor(): string {
    return getWidgetColor(this.widget)
  }

  get value(): string {
    if (this.widget.sensorType === 'Custom digital' || this.widget.sensorType === 'Toggle') {
      return this.widget.lastValue === this.widget.trueValue ? this.widget.trueText! : this.widget.falseText!
    }

    if (isNaN(+this.widget.lastValue)) {
      return this.widget.lastValue
    }

    return this.widget.rounding ? (+this.widget.lastValue).toFixed(this.widget.rounding) : this.widget.lastValue
  }

  get isToggleFormShow(): boolean {
    return this.widget.sensorType === 'Toggle'
  }

  get toggleFormData(): ToggleFormData {
    return getToggleFormData(this.widget)
  }

  get isValueShow(): boolean {
    if (this.widget.isMonitoring) {
      return this.widget.isValueShow
    }

    return true
  }
}
