import { Component, Watch, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { GridLayout, GridItem, GridItemData } from 'vue-grid-layout'
import DashboardCard from './DashboardCard/DashboardCard.vue'
import DashboardActions from './DashboardActions/Dashboard-Actions.vue'
import DashboardDialog from './DashboardDialog/Dashboard-Dialog.vue'
import SharedDashboardsDialog from './SharedDashboardsDialog/Shared-Dashboards-Dialog.vue'
import ShareDashboardDialog from './ShareDashboardDialog/Share-Dashboard-Dialog.vue'
import CircularLoader from '@/App/Components/CircularLoader/Circular-Loader.vue'
import NoContent from '@/App/Components/NoContent/No-Content.vue'
import CollapsedDashboardsDialog from './CollapsedDashboardsDialog/Collapsed-Dashboards-Dialog.vue'
import { DashboardService } from '@/App/Services/Dashboard.service'
import { UserService } from '@/App/Services/User.service'
import { IDashboard } from '@/App/Services/interfaces/dashboard.interface'
import { Widget } from '@/store/interfaces/widget.interface'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'
import { UserInfo } from '@/App/Services/interfaces/user.interface'

const dashboardStore = namespace('Dashboard')
const widgetStore = namespace('Widget')
const sharedStore = namespace('Shared')

@Component({
  components: {
    DashboardCard,
    DashboardActions,
    DashboardDialog,
    SharedDashboardsDialog,
    ShareDashboardDialog,
    CollapsedDashboardsDialog,
    CircularLoader,
    NoContent,
    GridLayout,
    GridItem
  }
})
export default class DashboardsPage extends Vue {
  $refs: {
    shareDashboardDialog: HTMLFormElement,
    dashboardDialog: HTMLFormElement,
    sharedDashboardsDialog: HTMLFormElement,
    dateTimePicker: HTMLFormElement,
    collapsedDashboardsDialog: HTMLFormElement
  }

  @dashboardStore.State
  public readonly dashboards: IDashboard[]

  @dashboardStore.Mutation
  private readonly setDashboards: (dashboards: IDashboard[]) => void

  @dashboardStore.Mutation
  private readonly deleteDashboard: (_id: string) => void

  @dashboardStore.Getter
  private readonly findDashboardById: (id: string) => IDashboard

  @widgetStore.Mutation
  private readonly setWidgets: (widgets: Widget[]) => void

  @sharedStore.State
  private readonly search: string

  @sharedStore.State
  private readonly userInfo: UserInfo

  @sharedStore.Mutation
  private readonly setUserInfo: (user: UserInfo) => void

  @sharedStore.Action
  private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public isLoading = false
  public dashboardsItems: IDashboard[] = []
  public layout: GridItemData[] = []

  private async mounted(): Promise<void> {
    try {
      this.isLoading = true

      let dashboards = await DashboardService.fetch()

      const widgets: Widget[] = []
      this.layout = [...this.userInfo.settings.dashboardsLayout]
      dashboards = dashboards.map(d => {
        if (d.type === 'monitoring') {
          d.widgets!.forEach(w => {
            widgets.push({ ...w, dashboardId: d._id })
          })
          delete d.widgets

          return d
        } else {
          return d
        }
      })

      this.setDashboards(dashboards)
      this.setWidgets(widgets)

      this.dashboardsItems = dashboards
    } catch {} finally {
      this.isLoading = false
    }
  }

  @Watch('dashboards')
  private DashboardsChanged(val: IDashboard[]): void {
    this.dashboardsItems = val
  }

  @Watch('search')
  private SearchChanged(): void {
    if (!this.search) {
      this.dashboardsItems = this.dashboards
      this.layout = [...this.userInfo.settings.dashboardsLayout]
    } else {
      this.dashboardsItems = this.dashboards
        .filter(d => d.name.toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase()) !== -1)
      this.layout = []
      this.dashboardsItems.forEach(d => {
        const layout = this.userInfo.settings.dashboardsLayout.find(l => l.i === d._id)
        if (layout) {
          this.layout.push(layout)
        }
      })
    }
  }

  @Watch('userInfo', { deep: true })
  private userInfoChanged(): void {
    this.layout = [...this.userInfo.settings.dashboardsLayout]
  }

  public onEdit(dashboard: IDashboard): void {
    this.$refs.dashboardDialog.edit(dashboard)
  }

  public onShare(_id: string): void {
    this.$refs.shareDashboardDialog.open([_id])
  }

  public async onRemove(_id: string): Promise<void> {
    try {
      const title = this.$t('dashboardsPage.deleteDashboardConfirmTitle').toString()
      const text = `
        ${this.$t('dashboardsPage.deleteDashboardConfirmText1')}<br/>
        ${this.$t('dashboardsPage.deleteDashboardConfirmText2')}<br/>
        ${this.$t('dashboardsPage.deleteDashboardConfirmText3')}
    `

      if (await this.confirm({ title, text })) {
        await DashboardService.destroy(_id)

        this.deleteDashboard(_id)

        const dashboardsLayout = this.userInfo.settings.dashboardsLayout.filter(d => d.i !== _id)
        this.setUserInfo({ ...this.userInfo, settings: { ...this.userInfo.settings, dashboardsLayout } })

        this.setNotification({ text: this.$t('dashboardsPage.dashboardDeletedNotificationText').toString() })
      }
    } catch {}
  }

  public async onDashboardLayoutChanged(): Promise<void> {
    try {
      const dashboardsLayout = this.layout.map(l => {
        delete l.moved

        return l
      })

      await UserService.updateSettings({ dashboardsLayout })
      this.setUserInfo({ ...this.userInfo, settings: { ...this.userInfo.settings, dashboardsLayout } })
    } catch {}
  }

  public openDashboardDialog(): void {
    this.$refs.dashboardDialog.open()
  }

  public openShareDashboardDialog(): void {
    this.$refs.shareDashboardDialog.open()
  }

  public openSharedDashboardsDialog(): void {
    this.$refs.sharedDashboardsDialog.open()
  }

  public openCollapsedDashboardsDialog(): void {
    this.$refs.collapsedDashboardsDialog.open()
  }

  get isUserNotOperator(): boolean {
    return this.userInfo.role !== 'operator'
  }

  get isNoContentBlockShow(): boolean {
    return (!this.dashboardsItems.length || !this.layout.length) && !this.isLoading && !this.search
  }
}
