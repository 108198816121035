import { render, staticRenderFns } from "./No-Shortcuts.vue?vue&type=template&id=32d44cb6&scoped=true&"
import script from "./No-Shortcuts.vue?vue&type=script&lang=js&"
export * from "./No-Shortcuts.vue?vue&type=script&lang=js&"
import style0 from "./No-Shortcuts.vue?vue&type=style&index=0&id=32d44cb6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32d44cb6",
  null
  
)

export default component.exports