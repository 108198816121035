import { Component, Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { DashboardMenuActions } from '@/App/Mixins'
import { UserService } from '@/App/Services/User.service'
import Header from './Header/Header.vue'
import NoShortcuts from './NoShortcuts/No-Shortcuts.vue'
import Shortcut from './Shortcut/Shortcut.vue'
import InsightDashboard from './InsightDashboard/Insight-Dashboard.vue'
import IntervalPicker from '@/App/Components/Pickers/IntervalPicker/Interval-Picker.vue'
import { IDashboard } from '@/App/Services/interfaces/dashboard.interface'
import { Widget } from '@/store/interfaces/widget.interface'
import { UserInfo } from '@/App/Services/interfaces/user.interface'
import { DateTimeInterval } from '@/App/Components/Pickers'
import { Notification } from '@/store/interfaces/shared.interfaces'

const widgetStore = namespace('Widget')
const sharedStore = namespace('Shared')

@Component({
  components: { Header, NoShortcuts, Shortcut, InsightDashboard, IntervalPicker }
})
export default class DashboardsCard extends Mixins(DashboardMenuActions) {
  $refs: {
    intervalPicker: HTMLFormElement,
    insightDashboard: HTMLFormElement
  }

  @Prop()
  public readonly dashboard: IDashboard

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  @sharedStore.State
  private readonly userInfo!: UserInfo

  @sharedStore.Mutation
  private readonly setUserInfo!: (user: UserInfo) => void

  @widgetStore.Getter
  private readonly widgetsByDashboardId: (id: string) => Widget[]

  public link=`dashboards/${this.dashboard._id}/widgets`
  public dateTimeInterval = {} as DateTimeInterval

  public async onMinimize(): Promise<void> {
    // Bug with Vuex if use property-decorator store not updated, so use old style to get real state
    const collapsedDashboards: string[] = [...this.$store.state.Shared.userInfo.settings.collapsedDashboards, this.dashboard._id]

    const settings = await UserService.updateSettings({ collapsedDashboards })

    this.setUserInfo({ ...this.userInfo, settings })

    this.setNotification({ text: this.$t('dashboardsPage.dashboardCard.dashboardHiddenNotificationText').toString() })
  }

  public selectDateTime(): void {
    this.$refs.intervalPicker.open()
  }

  public dropDateTimeToDefault(): void {
    this.dateTimeInterval = {} as DateTimeInterval
  }

  public refreshDate(): void {
    this.$refs.insightDashboard.updateData()
  }

  get widgets(): Widget[] {
    return this.widgetsByDashboardId(this.dashboard._id)
  }

  get isMonitoringType(): boolean {
    return this.dashboard.type === 'monitoring'
  }

  get isDropDateTimeBtnShow(): boolean {
    return !this.dateTimeInterval.from && !this.dateTimeInterval.to
  }
}
