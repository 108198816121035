import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { UserInfo } from '@/App/Services/interfaces/user.interface'

const sharedStore = namespace('Shared')

@Component
export default class DashboardActions extends Vue {
  public actionItems: ActionItem[] = []

  private mounted(): void {
    if (this.userInfo.role !== 'operator') {
      this.actionItems = [
        { icon: 'fa-plus', tooltip: this.$t('dashboardsPage.dashboardActions.addTooltipText').toString(), action: () => this.onOpenDashboardDialog() },
        { icon: 'fa-share', tooltip: this.$t('dashboardsPage.dashboardActions.shareTooltipText').toString(), action: () => this.onOpenShareDashboardDialog() },
        { icon: 'fa-th-list', tooltip: this.$t('dashboardsPage.dashboardActions.sharedTooltipText').toString(), action: () => this.onOpenSharedDashboardsDialog() },
        { icon: 'fa-columns', tooltip: this.$t('dashboardsPage.dashboardActions.concealedTooltipText').toString(), action: () => this.onOpenCollapsedDashboardsDialog() }
      ]
    } else {
      this.actionItems = [
        { icon: 'fa-columns', tooltip: this.$t('dashboardsPage.dashboardActions.concealedTooltipText').toString(), action: () => this.onOpenCollapsedDashboardsDialog() }
      ]
    }
  }

  @sharedStore.State
  private readonly userInfo: UserInfo

  public onOpenDashboardDialog(): void {
   this.$emit('onOpenDashboardDialog')
  }

  public onOpenShareDashboardDialog(): void {
    this.$emit('onOpenShareDashboardDialog')
  }

  public onOpenSharedDashboardsDialog(): void {
    this.$emit('onOpenSharedDashboardsDialog')
  }

  public onOpenCollapsedDashboardsDialog(): void {
    this.$emit('onOpenCollapsedDashboardsDialog')
  }

  get isUserOperator(): boolean {
    return this.userInfo.role !== 'operator'
  }
}

interface ActionItem {
  icon: string
  tooltip: string
  action: () => void
}
