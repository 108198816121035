import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { UserService } from '@/App/Services/User.service'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { UserInfo } from '@/App/Services/interfaces/user.interface'
import { VuetifyTableHeader } from '@/interfaces/vuetify'
import { IDashboard } from '@/App/Services/interfaces/dashboard.interface'

const sharedStore = namespace('Shared')
const dashboardStore = namespace('Dashboard')

@Component
export default class CollapsedDashboardsDialog extends Vue {
  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  @sharedStore.State
  private readonly userInfo: UserInfo

  @sharedStore.Mutation
  private readonly setUserInfo: (user: UserInfo) => void

  @dashboardStore.Getter
  private readonly findDashboardById: (id: string) => IDashboard

  public isOpen = false
  public isLoading = false
  public search = ''
  public headers: VuetifyTableHeader[] = [
    { text: this.$t('dashboardsPage.collapsedDashboardsDialog.tableHeaderName').toString(), align: 'left', sortable: true, value: 'name' },
    { text: this.$t('dashboardsPage.collapsedDashboardsDialog.tableHeaderAction').toString(), value: 'left', align: 'center', sortable: false }
  ]
  public collapsedDashboardsItems: TableData[] = []

  public open(): void {
    this.isOpen = true

    this.userInfo.settings.collapsedDashboards.forEach(d => {
      this.collapsedDashboardsItems.push({
        _id: d,
        name: this.findDashboardById(d).name
      })
    })
  }

  public close(): void {
    this.isOpen = false
    this.collapsedDashboardsItems = []
  }

  public async onShow(id: string): Promise<void> {
    try {
      this.isLoading = true

      const settings = await UserService.updateSettings({
        collapsedDashboards: this.userInfo.settings.collapsedDashboards.filter(d => d !== id)
      })

      this.setUserInfo({ ...this.userInfo, settings })

      this.collapsedDashboardsItems = this.collapsedDashboardsItems.filter(d => d._id !== id)

      this.setNotification({ text: this.$t('dashboardsPage.collapsedDashboardsDialog.dashboardShowNotificationText').toString() })
    } catch {} finally {
      this.isLoading = false
    }
  }
}

interface TableData {
  _id: string
  name: string
}
