import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { DashboardService } from '@/App/Services/Dashboard.service'
import { UserService } from '@/App/Services/User.service'
import { TABLE_HEADERS } from './constants'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { Dashboard } from '@/App/Services/interfaces/dashboard.interface'

const sharedStore = namespace('Shared')
const dashboardStore = namespace('Dashboard')

@Component
export default class SharedDashboardsDialog extends Vue {
  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  @sharedStore.Mutation
  private readonly toggleGlobalLoader: () => void

  @dashboardStore.Getter
  private readonly findDashboardById: (id: string) => Dashboard

  public isOpen = false
  public isLoading = false
  public search = ''
  public headers = TABLE_HEADERS
  public sharedDashboardsItems: SharedDashboard[] = []

  public async open(): Promise<void> {
    try {
      this.toggleGlobalLoader()

      const users = await UserService.fetch()
      this.sharedDashboardsItems = []

      users.forEach(({ sharedDashboards, login, _id }) => {
        sharedDashboards.forEach(d => {
          this.sharedDashboardsItems.push({
            id: d._id,
            userId: _id,
            dashboardId: d.dashboardId,
            userLogin: login,
            dashboardName: this.findDashboardById(d.dashboardId).name
          })
        })
      })
    } catch {} finally {
      this.isOpen = true
      this.toggleGlobalLoader()
    }
  }

  public close(): void {
    this.isOpen = false
  }

  public async onUnshared(dashboardId: string, userId: string): Promise<void> {
    try {
      this.isLoading = true

      await DashboardService.unshared(userId, dashboardId)

      this.sharedDashboardsItems = this.sharedDashboardsItems
        .filter(d => d.dashboardId !== dashboardId || d.userId !== userId)

      this.setNotification({ text: this.$t('dashboardsPage.sharedDashboardDialog.dashboardUnsharedNotificationText').toString() })

    } catch {} finally {
      this.isLoading = false
    }
  }
}

interface SharedDashboard {
  id: string
  userId: string
  dashboardId: string
  userLogin: string
  dashboardName: string
}
