import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DashboardMenuActions } from '@/App/Mixins'
import Menu from './Menu/Menu.vue'


@Component({
  components: { Menu }
})
export default class Header extends Mixins(DashboardMenuActions) {
  @Prop({ required: true })
  public readonly title: string

  @Prop({ required: true })
  public readonly link: string

  @Prop({ default: true })
  public readonly isMenuShow: boolean

  @Prop({ default: true })
  public readonly isFullBoardBtnShow: boolean

  @Prop({ default: false })
  public readonly isSelectTimeBtnShow: boolean

  @Prop({ default: false })
  public readonly isRefreshBtnShow: boolean

  @Prop({ default: false })
  public readonly isDropDateTimeBtnShow: boolean

  public onRefresh(): void {
    this.$emit('onRefresh')
  }

  public onMinimize(): void {
    this.$emit('onMinimize')
  }


  public onDateTimeSelect(): void {
    this.$emit('onDateTimeSelect')
  }

  public onDateTimeDropToDefault(): void {
    this.$emit('onDateTimeDropToDefault')
  }
}
