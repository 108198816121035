import { Component, Vue, Prop } from 'vue-property-decorator'
import { ChartData } from 'chart.js'
import { default as dayjs } from 'dayjs'
import { FlespiService } from '@/App/Services/Flespi.service'
import ChartDonut from '@/App/Components/Charts/Donut-Chart.vue'
import ChartBar from '@/App/Components/Charts/Bar-Chart.vue'
import { ChartType, IDeviceSettings, DefaultDateRange } from '@/App/Services/interfaces/dashboard.interface'
import { DateTimeInterval } from '@/App/Components/Pickers'

@Component({
  components: { ChartDonut, ChartBar }
})
export default class Summary extends Vue {
  @Prop({ required: true })
  public readonly deviceSettings: IDeviceSettings[]

  @Prop({ required: true })
  public readonly calculatorId: number

  @Prop({ required: true })
  public readonly chartType: ChartType

  @Prop()
  private readonly dateTimeInterval: DateTimeInterval

  @Prop({ default: false })
  private readonly isTotalRowShow: boolean

  @Prop()
  private readonly defaultDateRange: DefaultDateRange

  public tableData: TableData[] = []
  public chartData: ChartData | null = null
  public total = 0

  private mounted(): void {
    this.update()
  }

  private async setData(): Promise<void> {
    try {
      this.$emit('toggleLoading')

      const labels: string[] = []
      const values: number[] = []
      const colors: string[] = []
      for (const device of this.deviceSettings) {
        const data = await FlespiService.getDeviceCalculations(device.deviceId, this.calculatorId, await this.getDataRange())

        let result = 0
        data.forEach(d => {
          device.sensors.forEach(s => result += d[s.fieldName])
        })

        device.sensors.forEach(s => {
          labels.push(s.simpleName)
          colors.push( '#' + Math.floor(Math.random()*16777215).toString(16))
          values.push(+result.toFixed(2))

          this.tableData.push({
            name: s.simpleName,
            data: +result.toFixed(2)
          })
        })

        this.total += result
      }

      this.chartData = {
        labels,
        datasets: [
          {
            backgroundColor: colors,
            data: values
          }
        ]
      }
      this.total = +this.total.toFixed(2)
    } catch {} finally {
      this.$emit('toggleLoading')
    }
  }

  private async getDataRange(): Promise<DateTimeInterval> {
    // For avoid trouble when dateTimeInterval can be empty
    await new Promise(r => setTimeout(r, 50))
    if (this.dateTimeInterval.from && this.dateTimeInterval.to) {
      return this.dateTimeInterval
    } else {
      return {
        from: dayjs().subtract(this.defaultDateRange.last, this.defaultDateRange.unit).unix(),
        to: +(Date.now() / 1000).toFixed(0)
      }
    }
  }

  public update(): void {
    this.tableData = []
    this.total = 0

    this.setData()
  }

  get isDonutChartShow(): boolean {
    return this.chartType === 'donut'
  }

  get isBarChartShow(): boolean {
    return this.chartType === 'bar'
  }
}

interface TableData {
  name: string
  data: number
}
