import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { Calculator, FlespiService } from '@/App/Services/Flespi.service'
import { VuetifySelect } from '@/interfaces/vuetify'
import { Validator } from '@/App/Mixins'

@Component
export default class CalculatorSelect extends Mixins(Validator) {
  @Prop({ default: '' })
  private readonly value: number

  @Prop()
  private readonly filterBy: string

  public calculatorId: number | null = null
  public calculatorsItems: VuetifySelect[] = []
  public isLoading = false
  private calculators: Calculator[] = []

  @Watch('filterBy')
  private reportTypeChanged(): void {
    if (this.filterBy) {
        this.calculatorsItems = this.calculators.filter(c => c.name.toLowerCase().includes(this.filterBy)).map(c => {
            return { value: c.id, text: c.name }
        })
    } else {
        this.calculatorsItems = this.calculators.map(c => {
            return { value: c.id, text: c.name }
        })
    }
  }

  @Watch('value')
  private valueChanged(val: number): void {
    this.calculatorId = val
  }

  private async mounted(): Promise<void> {
    try {
      this.isLoading = true
      this.calculators = await FlespiService.fetchCalculators()

      this.reportTypeChanged()

      this.calculatorId = this.value
    } catch {} finally {
      this.isLoading = false
    }
  }

  public onSelect(): void {
    this.$emit('input', this.calculatorId)
  }
}
